import axios, { Canceler } from 'axios';
import httpClient from 'shared/services/http-client';
import { getStore, getTenant, getUrl } from 'common/services/token.service';
import { endpoints } from 'shared/services/endpoints.constants';
import { SecuritySensor, SecuritySensorWithCount, SecuritySensorDefaultSettingModel } from './model';
import { isInstallPending, isIssue, isOnePod, isUnsecureStatus } from './secureDisplays-util';
import { db } from '../../firebase';
import {PlanogramPositionObject} from "../planogram/model";

// endpoints
const ALL_SECURE_SENSOR_ALARM_MODULE_DATA = `/v1/model/tenants/{tenantId}/devices?classId=[alarm, vertical]&storeId={storeId}`;
const ALL_SECURITY_SENSOR_DATA = `/v1/model/tenants/{tenantId}/devices?classId=[onepod, vertical]&storeId={storeId}`;
const SINGLE_SECURITY_SENSOR_DATA = `/v1/model/tenants/{tenantId}/devices/{serNum}`;
const SECURITY_SENSOR_DEFAULT_SETTING_DATA = `/v1/model/tenants/{tenantId}/defaultDeviceSettings?type=security&tenantId={tenantId}`;

const COMMAND_TIMEOUT = 5000; // milliseconds

const { CancelToken } = axios;
let cancelFetchSensorDataReq: Canceler;

export const fetchSecureDisplaysAlarmModulesData = async (): Promise<SecuritySensor[]> => {
  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  const url = ALL_SECURE_SENSOR_ALARM_MODULE_DATA
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.get(url);
  const items = response.data;
  return items.filter((item: SecuritySensor) => !(item.isDeleted));
};

export const fetchDefaultSecuritySensorDeviceSettings = async ():
  Promise<SecuritySensorDefaultSettingModel> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant id');
  }
  const url = SECURITY_SENSOR_DEFAULT_SETTING_DATA
    .replace('{tenantId}', tenantId)
    .replace('{tenantId}', tenantId);
  const response = await httpClient.get(url);
  return response.data[0];
};

// onSnapshot updated code of fetchDefaultSecuritySensorDeviceSettings
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const streamDefaultSecuritySensorDeviceSettings = (observer: any): Function => {
  const tenantId = getTenant();
  const url = getUrl();
  if (!tenantId) {
    throw new Error('No tenant id');
  }
  return db.collection(`${url}/defaultDeviceSettings`)
    .where('type', '==', 'security')
    .where('tenantId', '==', tenantId)
    .onSnapshot(observer);
};

export const fetchSecureDisplaysData = async (positionsInSitePlan: PlanogramPositionObject[]): Promise<SecuritySensorWithCount> => {
  if (cancelFetchSensorDataReq) {
    cancelFetchSensorDataReq();
  }

  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  const url = ALL_SECURITY_SENSOR_DATA
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.get(url, {
    cancelToken: new CancelToken((canceler: Canceler) => {
      // An executor function receives a cancel function as a parameter
      cancelFetchSensorDataReq = canceler;
    }),
  });
  const items = response.data.length ? response.data : [];
  const count = {
    totalCount: items.length,
    sensorsWithIssuesCount: 0,
    secureStatusCount: 0,
    onePodCount: 0,
  };
  const { heartbeat } = await fetchDefaultSecuritySensorDeviceSettings();
  const filteredItems = (items?.length > 0)
    ? items.filter((item: SecuritySensor) => !(item.isDeleted)) : [];
  count.totalCount = filteredItems.length;
  if (filteredItems.length > 0) {
    filteredItems.forEach((item: SecuritySensor) => {
      if (isIssue(item, positionsInSitePlan)) {
        count.sensorsWithIssuesCount += 1;
      }
      if (isUnsecureStatus(item)) {
        if (!isInstallPending(item)) {
          count.secureStatusCount += 1;
        }
      }
      if (isOnePod(item)) {
        count.onePodCount += 1;
      }
    });
  }
  return {
    count,
    items: filteredItems.map((item: SecuritySensor) => ({
      ...item,
    })),
    heartbeat,
  };
};

// onSnapshot updated code of fetchSecureDisplaysData
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const streamSecureDisplaysData = (observer: any): Function => {
  const tenantId = getTenant();
  const url = getUrl();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No tenant/store id');
  }
  return db.collection(`${url}/devices`)
    .where('classId', 'in', ['onepod', 'vertical'])
    .where('storeId', '==', storeId)
    .onSnapshot(observer);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformSecureDisplaysData = async (list: any, positionsInStore: PlanogramPositionObject[]): Promise<SecuritySensorWithCount> => {
  const items = list.length ? list : [];
  const count = {
    totalCount: items.length,
    sensorsWithIssuesCount: 0,
    secureStatusCount: 0,
    onePodCount: 0,
  };
  const { heartbeat } = await fetchDefaultSecuritySensorDeviceSettings();
  const filteredItems = (items?.length > 0)
    ? items.filter((item: SecuritySensor) => !(item.isDeleted)) : [];
  count.totalCount = filteredItems.length;
  if (filteredItems.length > 0) {
    filteredItems.forEach((item: SecuritySensor) => {
      if (isIssue(item, positionsInStore)) {
        count.sensorsWithIssuesCount += 1;
      } else {
        count.secureStatusCount += 1;
      }
      if (isOnePod(item)) {
        count.onePodCount += 1;
      }
    });
  }
  return {
    count,
    items: filteredItems.map((item: SecuritySensor) => ({
      ...item,
    })),
    heartbeat,
  };
};

export const fetchSecuritySensor = async (serialNumber: string): Promise<SecuritySensor> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant/store id');
  }
  const url = SINGLE_SECURITY_SENSOR_DATA
    .replace('{tenantId}', tenantId)
    .replace('{serNum}', serialNumber);
  const response = await httpClient.get(url);
  return response.data;
};

// onSnapshot updated code of fetchDefaultSecuritySensorDeviceSettings
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const streamFetchSecuritySensor = (serialNumber: string, observer: any): Function => {
  const tenantId = getTenant();
  const url = getUrl();
  if (!tenantId) {
    throw new Error('No tenant id');
  }
  return db.collection(`${url}/devices`)
    .doc(serialNumber)
    .onSnapshot(observer);
};

export const deleteSecuritySensor = async (serialNumber: string): Promise<string> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant id');
  }
  const url = SINGLE_SECURITY_SENSOR_DATA
    .replace('{tenantId}', tenantId)
    .replace('{serNum}', serialNumber);
  const response = await httpClient.delete(url);
  return response.data;
};

export const fetchSecuritySensorData = async (): Promise<SecuritySensor[]> => {
  const tenantId = getTenant();
  const storeId = getStore();
  if (!tenantId || !storeId) {
    throw new Error('No Tenant and/or Store Id.');
  }
  const url = ALL_SECURITY_SENSOR_DATA
    .replace('{tenantId}', tenantId)
    .replace('{storeId}', storeId);
  const response = await httpClient.get(url);
  const items = response.data;
  return items.filter((item: SecuritySensor) => !(item.isDeleted));
};

export const fetchSecuritySensorStatus = async (serialNumber: string, classId: string):
  Promise<string> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant id');
  }
  const storeId = getStore();
  const data = {
    serialNum: serialNumber,
    storeId,
    classIds: [classId],
    command: {
      getState: {},
    },
  };

  const url = endpoints.ALARM_VERTICAL_SENSOR_STATUS;

  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenantId,
    },
  };

  const response = await httpClient.post(url, data, updatedConfig);
  return response.data;
};

export const disArmSecuritySensor = async (serialNumber: string): Promise<SecuritySensor> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant id');
  }

  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenantId,
    },
  };

  const storeId = getStore();
  const data = {
    serialNum: serialNumber,
    storeId,
    classIds: ['vertical', 'onepod'],
    command: {
      disarm: {
        duration: 30,
      },
    },
  };

  const url = endpoints.ALARM_VERTICAL_SENSOR_STATUS;

  const response = await httpClient.post(url, data, updatedConfig);
  return response.data;
};

export const disArmAllSecuritySensor = async (): Promise<SecuritySensor> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant id');
  }

  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenantId,
    },
  };

  const storeId = getStore();
  const data = {
    storeId,
    classIds: ['vertical', 'onepod'],
    command: {
      disarm: {
      },
    },
  };

  const url = endpoints.ALARM_VERTICAL_SENSOR_STATUS;

  const response = await httpClient.post(url, data, updatedConfig);
  return response.data;
};

export const silenceSecuritySensor = async (serialNumber: string): Promise<SecuritySensor> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant id');
  }

  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenantId,
    },
  };

  const storeId = getStore();
  const data = {
    serialNum: serialNumber,
    storeId,
    classIds: ['vertical'],
    command: {
      silence: {},
    },
  };

  const url = endpoints.ALARM_VERTICAL_SENSOR_STATUS;

  const response = await httpClient.post(url, data, updatedConfig);
  return response.data;
};

export const lockUnlockAllOnePodSensors = async (shouldLock: boolean): Promise<SecuritySensor> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant id');
  }

  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenantId,
    },
  };

  const storeId = getStore();
  const data = {
    storeId,
    classIds: ['onepod'],
    command: {
      lockdown: shouldLock,
    },
  };

  const url = endpoints.ALARM_VERTICAL_SENSOR_STATUS;

  const response = await httpClient.post(url, data, updatedConfig);
  return response.data;
};

export const lockUnlockOnepodSensor = async (serialNumber: string, lockStatus: boolean):
Promise<SecuritySensor> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant id');
  }
  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenantId,
    },
  };
  const storeId = getStore();
  const data = {
    serialNum: serialNumber,
    storeId,
    classIds: ['onepod'],
    command: {
      lockdown: lockStatus,
    },
  };
  const url = endpoints.ALARM_VERTICAL_SENSOR_STATUS;
  const response = await httpClient.post(url, data, updatedConfig);
  return response.data;
};

export const setPlanogramStatus = async (serialNumber: string): Promise<SecuritySensor> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant id');
  }

  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenantId,
    },
  };

  const storeId = getStore();
  const data = {
    serialNum: serialNumber,
    storeId,
    classIds: ['vertical', 'onepod'],
    command: {
      planogram: 'set',
    },
  };

  const url = endpoints.PLANOGRAM_STATUS;

  const response = await httpClient.post(url, data, updatedConfig);
  return response.data;
};

export const silenceOnepodSensor = async (serialNumber: string): Promise<SecuritySensor> => {
  const tenantId = getTenant();
  if (!tenantId) {
    throw new Error('No tenant id');
  }

  const updatedConfig = { // FIXME when cookie added
    headers: {
      tenantid: tenantId,
    },
  };

  const storeId = getStore();
  const data = {
    serialNum: serialNumber,
    storeId,
    classIds: ['onepod'],
    command: {
      silence: {},
    },
  };

  const url = endpoints.ALARM_VERTICAL_SENSOR_STATUS;

  const response = await httpClient.post(url, data, updatedConfig);
  return response.data;
};
