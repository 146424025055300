import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import { CircularProgress } from '@material-ui/core';
import useLoader from 'shared/hooks/useLoader';
import {
  SecuritySensor, SecuritySensorItemStatus, SecureDisplayListViewProps, SecuritySensorSort,
  SecuritySensorListViewItemData,
} from '../../model';
import { barcodeToSNFormat, matchesFilterCriteria } from '../../../../shared/services/utils';
import styles from './list-view.module.scss';
import { SearchContext } from '../../../app/Context';
import SecureDisplayListItem from './secure-display-list-item';
import {
  isSecuritySensorOnline, isIssue, isInstallPending,
  isReArmingFailed, isArmed, isLockable, isLocked,
  getDescriptionBySensorData, isAlarmNodeOnline, isComplianceIssue, isPlanogramFlagSet,
} from '../../secureDisplays-util';
import { PlanogramContext } from '../../../app/PlanogramContext';

const IssuesSecuritySensors = ({
  list, isDataLoaded,
}: SecureDisplayListViewProps): JSX.Element => {
  const { searchCriteria } = useContext(SearchContext);
  const { positionsInSitePlan } = useContext(PlanogramContext);
  const isLoading = useLoader({ isDataLoaded });
  const compare = (a: SecuritySensorSort, b: SecuritySensorSort): number => a.order - b.order;

  const getPathWithSerialNumber = (item: SecuritySensor): string => `/secure-displays/device/${barcodeToSNFormat(item.labelCode || '0000')}`;

  const renderSecuritySensorItem = (item: SecuritySensorListViewItemData): JSX.Element => (
    <div key={item.securitySensorData.labelCode}>
      <SecureDisplayListItem
        description={getDescriptionBySensorData(item.securitySensorData)}
        pathname={getPathWithSerialNumber(item.securitySensorData)}
        status={item.status}
        isLockedDown={item?.securitySensorData?.currentState?.isLockedDown}
      />
    </div>
  );

  const mapSecurityListItem = (items: SecuritySensor[]): SecuritySensorListViewItemData[] => items
    .filter((item: SecuritySensor) => isIssue(item, positionsInSitePlan)
      && matchesFilterCriteria(item, searchCriteria))
    .map((currentItem) => {
      let order = 19;
      const { currentState } = currentItem;
      if (currentState && (isReArmingFailed(currentItem) || !isArmed(currentItem))) {
        order = 1;
      } else if (currentState && !currentState?.isArmedAsExpected) {
        const { expectedArmedLoops, armedLoop } = currentState;
        if (expectedArmedLoops?.recoiler !== armedLoop?.recoiler) {
          order = 2;
        } else if ((expectedArmedLoops?.standPlunger !== armedLoop?.standPlunger)
          || (expectedArmedLoops?.sensorPlunger !== armedLoop?.sensorPlunger)) {
          order = 3;
        } else if (expectedArmedLoops?.apSense !== armedLoop?.apSense) {
          order = 4;
        } else if (expectedArmedLoops?.pigtail !== armedLoop?.pigtail) {
          order = 5;
        }
      } else if (isInstallPending(currentItem)) {
        order = 9;
      } else if (!isSecuritySensorOnline(currentItem)) {
        order = 10;
      } else if (!isPlanogramFlagSet(currentItem)) {
        order = 17;
      } else if (currentState && currentState?.isOnWrongStand) {
        order = 18;
      } else if (isComplianceIssue(currentItem, positionsInSitePlan)) {
        order = 19;
      }

      if (currentState && currentState?.alarmingLoop) {
        const { apSense, pigtail, recoiler,
          standPlunger, sensorPlunger } = currentState.alarmingLoop;
        if (recoiler) {
          order = 2;
        } else if (standPlunger || sensorPlunger) {
          order = 3;
        } else if (apSense) {
          order = 4;
        } else if (pigtail) {
          order = 5;
        }
      }
      return { ...currentItem, order };
    }).sort((a, b) => compare(a, b))
    .map((item) => {
      const status: SecuritySensorItemStatus = {};
      if (item.currentState && isReArmingFailed(item)) {
        status.reArmFailed = true;
      }
      if (item.currentState && !isArmed(item)) {
        status.isDisarmed = true;
        if (item.currentState && item.classId === 'onepod') {
          if (isLockable(item)) {
            if (isLocked(item)) {
              status.isLocked = true;
            } else {
              status.isUnlocked = true;
            }
          } else {
            status.problemInLock = true;
          }
        }
      }
      if (isInstallPending(item)) {
        status.isInstallPending = true;
      } else if (!isSecuritySensorOnline(item)) {
        status.isOffline = true;
      } else if (isReArmingFailed(item)) {
        status.reArmFailed = true;
      } else if (!isAlarmNodeOnline(item)) {
        status.isAlarmNodeOffline = true;
      }
      if (!item.currentState?.isArmedAsExpected) {
        const { expectedArmedLoops, armedLoop } = item?.currentState || {};
        // Comparing expected armed loop with actual armed loop, to find issue with sensor.
        if (expectedArmedLoops?.apSense !== armedLoop?.apSense) {
          status.merchandisePowerConnector = true;
        } else if (expectedArmedLoops?.pigtail !== armedLoop?.pigtail) {
          status.sensorCableToMerchandise = true;
        } else if (expectedArmedLoops?.auxPort !== undefined
          && expectedArmedLoops?.auxPort !== armedLoop?.auxPort) {
          status.auxiliaryPort = true;
        } else if (expectedArmedLoops?.recoiler !== armedLoop?.recoiler) {
          status.sensorTetherCable = true;
        } else if (expectedArmedLoops?.standPlunger !== armedLoop?.standPlunger) {
          status.standPlunger = true;
        } else if (expectedArmedLoops?.sensorPlunger !== armedLoop?.sensorPlunger) {
          status.sensorPlunger = true;
        }
      }
      if (item.currentState && item.currentState.alarmingLoop) {
        const { apSense, pigtail, auxPort, recoiler,
          standPlunger, sensorPlunger } = item.currentState.alarmingLoop;
        if (apSense) {
          status.merchandisePowerConnector = true;
        } else if (pigtail) {
          status.sensorCableToMerchandise = true;
        } else if (auxPort) {
          status.auxiliaryPort = true;
        } else if (recoiler) {
          status.sensorTetherCable = true;
        } else if (standPlunger) {
          status.standPlunger = true;
        } else if (sensorPlunger) {
          status.sensorPlunger = true;
        }
      }
      if (item.currentState && item.currentState?.isOnWrongStand) {
        status.sensorWrongStand = true;
      }
      if (isComplianceIssue(item, positionsInSitePlan)) {
        status.planogramCompliance = true;
      }
      if (!isPlanogramFlagSet(item)) {
        status.confirmationNeeded = true;
      }
      return {
        securitySensorData: item,
        status,
      };
    });

  const showHide = isLoading ? styles.hideList : styles.showList;

  return (
    <>
      { isLoading ? <CircularProgress /> : null }
      <Container className={`${styles.container} ${showHide}`}>
        {mapSecurityListItem(list).map(renderSecuritySensorItem)}
      </Container>
    </>
  );
};

export default React.memo(IssuesSecuritySensors);
