import React, { useState, useEffect, useRef, useContext } from 'react';
import { useIntl } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Button, Tabs, Tab, Divider, Box, IconButton, Dialog, makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useHistory } from 'react-router-dom';
import NoDeviceSetup from 'shared/ui/noDeviceSetup';
import SearchField from 'shared/ui/mobileUI-search-field';
import { LOADING_STATE } from 'shared/ui/utils/constants';
import { isGatewayNotAssigned } from 'modules/gateways/gateway-utils';
import { GatewayContext } from 'modules/app/GatewayContext';
import { DataContext } from 'modules/app/DataContext';
import AllSensors from './views/All';
import IssuesSecuritySensors from './views/Issues';
import { fetchSecureDisplaysData, fetchDefaultSecuritySensorDeviceSettings, lockUnlockAllOnePodSensors, disArmAllSecuritySensor, streamSecureDisplaysData, streamDefaultSecuritySensorDeviceSettings } from '../secureDisplays.service';
import { SecuritySensor, SecuritySensorWithCount, SecuritySensorCount, SecuritySensorDefaultSettingModel } from '../model';
import styles from './index.scss';
import ConfirmDialog from '../ConfirmDialog';
import SecuritySensorConfirmDialog from '../SecuritySensorConfirmDialog';
import { PlanogramContext } from '../../app/PlanogramContext';
import { isDisarmed } from '../secureDisplays-util';

export const initialSecurityItemsList: SecuritySensor[] = [];
export const defaultSecurityItemsCount: SecuritySensorCount = {
  totalCount: 0,
  sensorsWithIssuesCount: 0,
  secureStatusCount: 0,
  onePodCount: 0,
};
export const initialDefaultSettingOSA: SecuritySensorDefaultSettingModel = {
  heartbeat: 0,
  nightlyRemovalPeriod: 0,
};

interface Props {
  setIconState(loadingState: string): void;
  iconState: string;
}

const useStyles = makeStyles({
  dialogPaper: {
    minHeight: '25%',
    minWidth: '85%',
    overflow: 'auto',
  },
  loadingContainer: {
    width: '45%',
    display: 'flex',
    marginLeft: '85%',
    marginBottom: '5px',
  },
  confirmationText: {
    color: '#0000008a',
    margin: '10px 0 20px 0',
  },
  retryingIcon: {
    marginTop: '5px',
  },
  sentConfirmation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sentIcon: {
    display: 'inline-block',
    marginLeft: '8px',
    marginTop: '3px',
    color: '#257525',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  retryingProgress: {
    top: '50%',
    left: '50%',
  },
  retryingButton: {
    height: '38px',
  },
  fullWidthCenterAligned: {
    width: '100%',
    textAlign: 'center',
  },
  width80Percent: {
    width: '80%',
  },
});

const ONEPOD_SENSOR_ACK_TIMEOUT = 10; // in seconds
const ONEPOD_SENSOR_RETRY_TIMEOUT = 30; // in seconds
const RESET_TIMEOUT = 2000; // in milliseconds
const MILLISECONDS_TO_SECONDS = 1000; // to convert milliseconds to seconds

const SecureDisplaysList = (props: Props): JSX.Element => {
  const { allSSList, setAllSSList, isLoading, setShowLoader, hideLoader } = useContext(DataContext);
  const { displaySecurityData } = allSSList;
  const { iconState, setIconState } = props;
  const classes = useStyles();
  const [refresh, setRefresh] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [securityDefaultSetting, setSecurityDefaultSetting] = useState(() =>
    initialDefaultSettingOSA);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDisarmAllConfirmDialog, setOpenDisarmAllConfirmDialog] = useState(false);
  const [actionButtonClicked, setActionButtonClicked] = useState(false);
  const [ackReceived, setAckReceived] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const [timeoutEnd, setTimeoutEnd] = useState(false);
  const [loadingState, setLoadingState] = useState<string>('');
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [rearmingTime, setRearmingTime] = useState(() => allSSList?.rearmingTime || 0);
  const [lockClicked, setLockClicked] = useState(false);
  const [unlockClicked, setUnlockClicked] = useState(false);
  const [disarmAllClicked, setdisarmAllClicked] = useState(false);
  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false);
  const [disableOtherButtons, setDisableOtherButtons] = useState<boolean>(false);
  const { gatewayCurrentStatus, isPanicSituation } = useContext(GatewayContext);
  const { positionsInSitePlan } = useContext(PlanogramContext);

  const isDataLoaded = useRef(false);

  const isGatewayNotProvisioned = isGatewayNotAssigned(gatewayCurrentStatus);

  const { formatMessage: i18n } = useIntl();
  const displaySecurityStr = i18n({ id: 'common.displaySecurity' });
  const lockAllStr = i18n({ id: 'devices.lockAll' });
  const unLockAllStr = i18n({ id: 'devices.unLockAll' });
  const disArmAllStr = i18n({ id: 'devices.disArmAll' });
  const secureStatusStr = i18n({ id: 'devices.secureStatus' });
  const issueStr = i18n({ id: 'common.issues' });
  const retryingStr = i18n({ id: 'devices.retrying' });
  const sentStr = i18n({ id: 'devices.sent' });
  const disarmAllMessageStr = i18n({ id: 'devices.disArmAllConfirmationMessage' }, { rearmingTime });
  const history = useHistory();

  const displayIndex = useRef(sessionStorage.getItem('securitySensorTabIndex'));

  const onePodCount = displaySecurityData?.onePodCount ?? 0;
  const totalCount = displaySecurityData?.count?.totalCount ?? 0;
  const hasNoDevices = totalCount === 0;
  const hasNoOnePods = onePodCount === 0;
  const hasOnlyVertical = totalCount > 0 && hasNoOnePods;

  const refreshSensorData = (): void => {
    setRefresh((prevState) => !prevState);
  };

  useEffect(() => {
    setIconState('');
  }, []);

  useEffect(() => {
    const displayItemsCount = allSSList.displaySecurityData?.items?.length;
    if (displayItemsCount !== 0) {
      hideLoader();
    } else {
      setShowLoader(true);
    }
    fetchDefaultSecuritySensorDeviceSettings().then((item) => {
      setSecurityDefaultSetting(item);
      const rearmTime = item?.nightlyRemovalPeriod ? item?.nightlyRemovalPeriod / 60 / 1000 : 0;
      setRearmingTime(rearmTime);
    });
    if (displayItemsCount === 0 || dataUpdated) {
      fetchSecureDisplaysData(positionsInSitePlan).then(({ count, items, heartbeat }: SecuritySensorWithCount) => {
        if (count && items) {
          // TODO: Add constant for vertical and onepod.
          const secSensorItems = items.filter((sensor: SecuritySensor) => sensor.classId === 'vertical' || sensor.classId === 'onepod');
          const disarmedCnt = secSensorItems.filter((item: SecuritySensor) => isDisarmed(item)).length;
          setAllSSList((prevState) => ({
            ...prevState,
            secSensorList: secSensorItems,
            disArmedCount: disarmedCnt,
            issuesCount: count.sensorsWithIssuesCount,
            rearmingTime,
            displaySecurityData: {
              items: secSensorItems,
              count,
              onePodCount: count.onePodCount,
              heartbeat,
            },
          }));

          if (displayIndex.current != null) {
            setTabIndex(Number(displayIndex.current));
          } else if (count.sensorsWithIssuesCount > 0) {
            setTabIndex(1);
          }

          let matchingDevices: string[] = [];
          secSensorItems.forEach((item) => {
            const { classId, storeId, serialNum } = item;
            const storeIdToMatch = sessionStorage.getItem('store_key');
            const isMatching = storeId === storeIdToMatch && (classId === 'onepod' || classId === 'vertical');
            const currentTime = Date.now();

            let secondsLapsed = 0;
            let commandSentTimeMs = 0;
            let ackRecd = false;
            if (disarmAllClicked && item.lastDisarmEvent) {
              ({ lastDisarmEvent: { commandSentTimeMs, ackRecd } } = item);
              secondsLapsed = (currentTime - commandSentTimeMs) / MILLISECONDS_TO_SECONDS;
            } else if ((lockClicked || unlockClicked) && item.lastLockdownEvent) {
              ({ lastLockdownEvent: { commandSentTimeMs, ackRecd } } = item);
              secondsLapsed = (currentTime - commandSentTimeMs) / MILLISECONDS_TO_SECONDS;
            }

            if (isMatching) {
              matchingDevices.push(serialNum);
            }
            if (isMatching) {
              if (actionButtonClicked) {
                if (!ackRecd && secondsLapsed > ONEPOD_SENSOR_RETRY_TIMEOUT) {
                  setIsTimeout(false);
                  setTimeoutEnd(true);
                  setLoadingState(LOADING_STATE.ERROR);
                  setDisableOtherButtons(false);
                  setIconState('');
                  setTimeout(() => {
                    setOpenDisarmAllConfirmDialog(false);
                    setOpenConfirmDialog(false);
                    setAckReceived(false);
                    setActionButtonClicked(false);
                    setLoadingState('');
                    setDisableOtherButtons(false);
                    setdisarmAllClicked(false);
                    setIsTimeout(false);
                  }, RESET_TIMEOUT);
                } else if (!ackRecd && secondsLapsed > ONEPOD_SENSOR_ACK_TIMEOUT) {
                  setIsTimeout(true);
                  setTimeoutEnd(false);
                  setLoadingState(LOADING_STATE.RETRYING);
                } else if (ackRecd && matchingDevices.includes(serialNum)) {
                  matchingDevices = matchingDevices
                    .filter((device: string) => device !== serialNum);
                  if (matchingDevices.length === 0) {
                    setAckReceived(true);
                    setIsTimeout(false);
                    setTimeoutEnd(false);
                    setLoadingState(LOADING_STATE.SENT);
                    setIsActionButtonDisabled(true);

                    setTimeout(() => {
                      setOpenDisarmAllConfirmDialog(false);
                      setOpenConfirmDialog(false);
                      setAckReceived(false);
                      setActionButtonClicked(false);
                      setLoadingState('');
                      setDisableOtherButtons(false);
                      setdisarmAllClicked(false);
                      setIsActionButtonDisabled(false);
                      setIsTimeout(false);
                    }, RESET_TIMEOUT);
                  }
                  setIconState('');
                }
              }
            }
            if (ackRecd && secondsLapsed > ONEPOD_SENSOR_RETRY_TIMEOUT) {
              setIsActionButtonDisabled(false);
              setDisableOtherButtons(false);
              setIconState('');
            }
            if (!ackRecd && secondsLapsed > ONEPOD_SENSOR_RETRY_TIMEOUT) {
              setIconState('');
            }
          });
        }
        if (isLoading) {
          hideLoader();
        }
        // eslint-disable-next-line no-console
      }).catch((e) => console.error(e));
      setDataUpdated(false);
    } else {
      hideLoader();
    }
  }, [refresh]);

  useEffect(() => {
    const unsubscribe = streamDefaultSecuritySensorDeviceSettings({
      next: async () => {
        setDataUpdated(true);
        refreshSensorData();
      },
    });
    return (): void => {
      unsubscribe();
      hideLoader();
    };
  }, []);

  useEffect(() => {
    const unsubscribe = streamSecureDisplaysData({
      next: async () => {
        setDataUpdated(true);
        refreshSensorData();
      },
    });
    return (): void => unsubscribe();
  }, []);

  const back = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    history.push('/dashboard');
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    sessionStorage.setItem('securitySensorTabIndex', String(newValue));
    displayIndex.current = String(newValue);
    setTabIndex(newValue);
  };

  const renderLockAllPopup = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    setUnlockClicked(false);
    setLockClicked(true);
    setdisarmAllClicked(false);
    setOpenConfirmDialog(true);
    refreshSensorData();
  };

  const renderUnlockAllPopup = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    setLockClicked(false);
    setUnlockClicked(true);
    setdisarmAllClicked(false);
    setOpenConfirmDialog(true);
    refreshSensorData();
  };

  const handleLockUnlockAll = (): void => {
    setActionButtonClicked(true);
    setIconState(LOADING_STATE.LOADING);
    lockUnlockAllOnePodSensors(lockClicked);
    setIsConfirmPopupOpen(true);
  };

  const handleClose = (): void => {
    setOpenConfirmDialog(false);
    setOpenDisarmAllConfirmDialog(false);
    setIsConfirmPopupOpen(false);
  };

  const handleDisarmAll = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    setdisarmAllClicked(true);
    setLockClicked(false);
    setUnlockClicked(false);
    setOpenDisarmAllConfirmDialog(true);
  };

  const getDisarmAllButton = ({ fullWidth }: { fullWidth: boolean }): JSX.Element => {
    if (fullWidth) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={handleDisarmAll}
          className={classes.width80Percent}
          disabled={isGatewayNotProvisioned || (isActionButtonDisabled && disarmAllClicked)}
        >
          {disArmAllStr}
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        color="primary"
        onClick={handleDisarmAll}
        disabled={isGatewayNotProvisioned || (isActionButtonDisabled && disarmAllClicked)}
      >
        {disArmAllStr}
      </Button>
    );
  };

  const getLockAllButton = (): JSX.Element => (
    <Button
      variant="contained"
      color="primary"
      onClick={renderLockAllPopup}
      disabled={isGatewayNotProvisioned
          || (isActionButtonDisabled && (lockClicked || unlockClicked))
          || disableOtherButtons}
    >
      {lockAllStr}
    </Button>
  );

  const getUnlockAllButton = (): JSX.Element => (
    <Button
      variant="contained"
      color="primary"
      onClick={renderUnlockAllPopup}
      disabled={isGatewayNotProvisioned
        || (isActionButtonDisabled && (lockClicked || unlockClicked))
        || disableOtherButtons || isPanicSituation}
    >
      {unLockAllStr}
    </Button>
  );

  const getLoadingState = (): JSX.Element => {
    let ButtonStateJSX;
    switch (loadingState) {
      case LOADING_STATE.RETRYING:
        if (isConfirmPopupOpen === false) {
          ButtonStateJSX = (
            <>
              {retryingStr.toUpperCase()}
              <span>
                <CircularProgress color="primary" size={24} className={classes.retryingProgress} />
              </span>
            </>
          );
        } else {
          ButtonStateJSX = lockClicked ? getLockAllButton()
            : unlockClicked ? getUnlockAllButton() : getDisarmAllButton({ fullWidth: false });
        }
        break;
      case LOADING_STATE.LOADING:
        ButtonStateJSX = (
          <CircularProgress color="primary" size={24} className={classes.retryingProgress} />
        );
        break;
      case LOADING_STATE.SENT:
        ButtonStateJSX = (
          <>
            {sentStr.toUpperCase()}
            <span className={classes.sentIcon}>
              <CheckCircleOutlineIcon />
            </span>
          </>
        );
        break;
      default:
        ButtonStateJSX = <div />;
        break;
    }
    return ButtonStateJSX;
  };

  const renderSecuritySensorView = (index: number, list: SecuritySensor[]): JSX.Element => {
    switch (index) {
      case 0:
        return (
          <AllSensors
            list={list}
            heartbeat={securityDefaultSetting.heartbeat}
            iconState={iconState}
            isDataLoaded={isDataLoaded}
          />
        );
      case 1:
        return (
          <IssuesSecuritySensors
            list={list}
            heartbeat={securityDefaultSetting.heartbeat}
            isDataLoaded={isDataLoaded}
          />
        );
      default:
        return (
          <AllSensors
            list={list}
            heartbeat={securityDefaultSetting.heartbeat}
            iconState={iconState}
            isDataLoaded={isDataLoaded}
          />
        );
    }
  };

  const handleActionPerform = (action: string): void => {
    if (action === 'disarm') {
      setIsConfirmPopupOpen(true);
      setActionButtonClicked(true);
      disArmAllSecuritySensor();
      setDisableOtherButtons(true);
      refreshSensorData();
    }
  };

  const getButton = (): JSX.Element | null => {
    if (hasNoDevices) {
      return null;
    }

    if (hasOnlyVertical) {
      return disarmAllClicked && loadingState !== '' && loadingState !== LOADING_STATE.ERROR
        ? getLoadingState()
        : getDisarmAllButton({ fullWidth: true });
    }

    return (
      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {
          onePodCount > 0 && lockClicked && loadingState !== '' && loadingState !== LOADING_STATE.ERROR ? getLoadingState()
            : getLockAllButton()
        }
        {
          onePodCount > 0 && unlockClicked && loadingState !== '' && loadingState !== LOADING_STATE.ERROR ? getLoadingState()
            : getUnlockAllButton()
        }
        {
          onePodCount > 0 && disarmAllClicked && loadingState !== ''
            && loadingState !== LOADING_STATE.ERROR
            ? getLoadingState()
            : getDisarmAllButton({ fullWidth: false })
        }
      </Box>
    );
  };

  const SecuritySensorContent = displaySecurityData?.items?.length
    ? (
      <>
        <div className={`${styles.btnContainer} ${hasOnlyVertical ? classes.fullWidthCenterAligned : ''}`}>
          {getButton()}
        </div>
        {
          disarmAllClicked
          && (
            <Dialog classes={{ paper: classes.dialogPaper }} open={openDisarmAllConfirmDialog}>
              <ConfirmDialog
                disarmAllClicked={disarmAllClicked}
                heading={disArmAllStr}
                message={disarmAllMessageStr}
                onClose={handleClose}
                onActionPerform={handleActionPerform}
                actionButtonClicked={actionButtonClicked}
                timeoutEnd={timeoutEnd}
                ackReceived={ackReceived}
                isTimeout={isTimeout}
                loadingState={loadingState}
                setLoadingState={setLoadingState}
                isActionButtonDisabled={isActionButtonDisabled}
                setDisableOtherButtons={setDisableOtherButtons}
              />
            </Dialog>

          )
        }
        <Divider className={styles.divider} />
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          variant="fullWidth"
          onChange={handleChange}
          style={{ minWidth: '100%' }}
        >
          <Tab data-testid="secure-status-tab" label={`${secureStatusStr} (${displaySecurityData?.count?.totalCount})`} />
          <Tab data-testid="secure-issues-tab" label={`${issueStr} (${displaySecurityData?.count?.sensorsWithIssuesCount})`} />
        </Tabs>
        <SearchField />
        {renderSecuritySensorView(tabIndex, displaySecurityData?.items)}
        <Dialog classes={{ paper: classes.dialogPaper }} open={openConfirmDialog}>
          <SecuritySensorConfirmDialog
            onClose={handleClose}
            onLockAll={handleLockUnlockAll}
            actionButtonClicked={actionButtonClicked}
            timeoutEnd={timeoutEnd}
            ackReceived={ackReceived}
            isTimeout={isTimeout}
            loadingState={loadingState}
            setLoadingState={setLoadingState}
            lock={lockClicked}
            unlock={unlockClicked}
            isActionButtonDisabled={isActionButtonDisabled}
            setDisableOtherButtons={setDisableOtherButtons}
          />
        </Dialog>
      </>
    ) : (
      <NoDeviceSetup />
    );

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className="header-box"
      >
        <IconButton onClick={back}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography className="list-title">{displaySecurityStr}</Typography>
        <div />
      </Box>
      { !isLoading ? SecuritySensorContent : null }
    </>
  );
};

export default SecureDisplaysList;
