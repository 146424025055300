import React from 'react';
import { useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Paper, Box, Divider } from '@material-ui/core';
import { LOADING_STATE } from 'shared/ui/utils/constants';
import { useHistory } from 'react-router-dom';
import { firstLetterOfWordInUpperCase } from 'shared/services/utils';
import { DescriptionText } from '../../../../../shared/ui/device-list/list-item/model';
import { SecuritySensorItemStatus } from '../../../model';
import LockStatusUnknown from '../../../../../shared/assets/lock-status-unknown.svg';
import DisarmStatus from '../../../../../shared/assets/Red-disarmed.svg';
import ArmedStatus from '../../../../../shared/assets/armed.svg';
import issueStatus from '../../../../../shared/assets/warning.svg';

interface SecureDisplayListItemProps {
  description: DescriptionText;
  pathname?: string;
  status: SecuritySensorItemStatus;
  iconState?: string;
  isLockedDown?: boolean;
  classId?: string;
}

const useStyle = makeStyles({
  textBold: {
    fontWeight: 'bold',
    color: 'rgb(123,123,123)',
  },
  textNormal: {
    color: 'rgb(123,123,123)',
  },
  statusText: {
    color: 'rgb(123,123,123)',
    textAlign: 'right',
  },
  offlineText: {
    color: 'red',
    textAlign: 'right',
  },
  alarmText: {
    backgroundColor: 'red',
    width: '45%',
    marginLeft: '55%',
    position: 'relative',
    alignItems: 'center',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    textAlign: 'center',
    color: 'white',
  },
  statusIconRed: {
    fill: 'red',
    float: 'right',
    marginRight: '5px',
    height: '22px',
  },
  statusIcon: {
    float: 'right',
    marginRight: '5px',
    height: '22px',
  },
  paper: {
    marginBottom: '1.5rem',
    backgroundColor: '#f1f1f1',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0.7em',
  },
  bottomBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingBottom: '0.7em',
  },
  divider: {
    margin: 0,
    backgroundColor: 'rgb(181, 181, 181)',
  },
});

const SecureDisplayListItem = (props: SecureDisplayListItemProps): JSX.Element => {
  const styles = useStyle(props);
  const history = useHistory();
  const { description, pathname, status, iconState, isLockedDown,
    classId } = props;
  const merchandiseName = description.text.length <= 40 ? description.text : `${description.text.substring(0, 40)}...`;
  const positionName = description.subText && description.subText.length <= 40 ? description.subText : `${description.subText?.substring(0, 40)}...`;

  const { formatMessage: i18n } = useIntl();
  const statusStr = i18n({ id: 'dashboard.status' });
  const alarmStr = i18n({ id: 'devices.alarm' });
  const offlineStr = i18n({ id: 'devices.offline' }).toUpperCase();
  const installPendingStr = i18n({ id: 'devices.installPending' }).toUpperCase();

  const labels = {
    attachedAlarmModuleOffline: i18n({ id: 'devices.attachedAlarmModuleOffline' }),
    merchandisePowerConnector: i18n({ id: 'common.merchandisePowerConnector' }),
    sensorCableToMerchandise: i18n({ id: 'common.sensorCableToMerchandise' }),
    auxiliaryPort: i18n({ id: 'common.auxiliaryPort' }),
    sensorTetherCable: i18n({ id: 'common.sensorTetherCable' }),
    standPlunger: i18n({ id: 'common.standPlunger' }),
    sensorPlunger: i18n({ id: 'common.sensorPlunger' }),
    sensorWrongStand: i18n({ id: 'common.sensorWrongStand' }),
    planogramCompliance: i18n({ id: 'common.planogramCompliance' }),
    confirmationNeeded: i18n({ id: 'devices.confirmationNeeded' }),
  };

  const isRenderStatus = (): boolean => !status?.isAlarming && !status?.isOffline
    && !status?.isInstallPending;

  const isOnePod = classId === 'onepod';

  const handleRedirect = (): void => {
    history.push(pathname || '/');
  };

  const getIssueText = (securityStatus: SecuritySensorItemStatus): string => {
    switch (true) {
      case (securityStatus?.isAlarmNodeOffline):
        return labels.attachedAlarmModuleOffline;
      case (securityStatus?.merchandisePowerConnector):
        return labels.merchandisePowerConnector;
      case (securityStatus?.sensorCableToMerchandise):
        return labels.sensorCableToMerchandise;
      case (securityStatus?.auxiliaryPort):
        return labels.auxiliaryPort;
      case (securityStatus?.sensorTetherCable):
        return labels.sensorTetherCable;
      case (securityStatus?.standPlunger):
        return labels.standPlunger;
      case (securityStatus?.sensorPlunger):
        return labels.sensorPlunger;
      case (securityStatus?.sensorWrongStand):
        return labels.sensorWrongStand;
      case (securityStatus?.planogramCompliance):
        return labels.planogramCompliance;
      case (securityStatus?.confirmationNeeded):
        return labels.confirmationNeeded;
      default:
        return '';
    }
  };

  return (
    <>
      <Paper key={description.text + pathname} elevation={3} className={styles.paper}>
        <div role="button" tabIndex={0} onClick={handleRedirect} onKeyDown={handleRedirect} color="inherit">
          <Box className={styles.box}>
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <Typography key={pathname} variant="body1" noWrap className={styles.textBold}>
                  {merchandiseName}
                </Typography>
                <Typography variant="body1" noWrap className={styles.textNormal}>
                  {positionName}
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ alignItems: 'center', display: 'flex' }}>
                <KeyboardArrowRightIcon />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box className={styles.bottomBox}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography variant="body1" noWrap className={styles.textNormal}>
                  {statusStr}
                </Typography>
              </Grid>
              <Grid item xs={8} style={{ alignItems: 'left' }}>
                {(status.isAlarming)
                  && (
                    <Typography variant="body1" noWrap className={styles.alarmText}>
                      {alarmStr}
                    </Typography>
                  )}
                {(!status.isAlarming && status.isInstallPending)
                  && (
                    <Typography variant="body1" noWrap className={styles.statusText}>
                      {installPendingStr}
                    </Typography>
                  )}
                {(!status.isAlarming && status.isOffline)
                && (
                  <Typography variant="body1" noWrap className={styles.offlineText}>
                    {offlineStr}
                  </Typography>
                )}
                {iconState && isOnePod ? (
                  <>
                    {iconState === LOADING_STATE.LOADING && status.isArmed ? (
                      <>
                        <img src={ArmedStatus} alt="Armed" className={styles.statusIcon} />
                      </>
                    ) : null}
                    <img src={LockStatusUnknown} alt="Unknown status" className={styles.statusIconRed} />
                  </>
                ) : (
                  <>
                    {(isRenderStatus() && status.reArmFailed)
                      && (
                        <img src={issueStatus} alt="issue" className={styles.statusIcon} />
                      )}
                    {(isRenderStatus() && status.isArmed)
                      && (
                        <img src={ArmedStatus} alt="Armed" className={styles.statusIcon} />
                      )}
                    {(isRenderStatus() && status.isDisarmed)
                      && (
                        <img src={DisarmStatus} alt="DisArmed" className={styles.statusIconRed} />)}
                    {(isRenderStatus() && isOnePod && isLockedDown)
                      && (
                        <LockIcon className={styles.statusIcon} />)}
                    {(isRenderStatus() && isOnePod && !isLockedDown)
                      && (
                        <LockOpenIcon className={styles.statusIcon} />)}
                  </>
                )}
                {getIssueText(status) && !status.isAlarming && !status.isInstallPending
                  && !status.isOffline && isRenderStatus() && !status.isDisarmed
                  && (
                    <Typography variant="body1" noWrap className={styles.offlineText}>
                      {firstLetterOfWordInUpperCase(getIssueText(status))}
                    </Typography>
                  )}
              </Grid>
            </Grid>
          </Box>
        </div>
      </Paper>
    </>
  );
};

export default SecureDisplayListItem;
