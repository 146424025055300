import _ from 'lodash';
import { firstLetterOfWordInUpperCase } from 'shared/services/utils';
import { DescriptionText } from 'shared/ui/device-list/list-item/model';
import { LabelsModel, SecuritySensor } from './model';
import { PlanogramPositionObject } from '../planogram/model';

export const isSecuritySensorOnline = (item: SecuritySensor): boolean => item?.isOnline || false;

export const isReArmingFailed = (item: SecuritySensor): boolean => {
  const reArmIntervalSeconds = 30;
  if (item.lastDisarmEvent && item.currentState
    && (Date.now() <= item.lastDisarmEvent?.commandSentTimeMs + reArmIntervalSeconds)
    && item.currentState.isArmed === false) {
    return true;
  }
  return false;
};

export const isInstallPending = (item: SecuritySensor): boolean => !item.currentState;

export const isComplianceIssue = (
  item: SecuritySensor, positionsInSitePlan: PlanogramPositionObject[],
): boolean | undefined => {
  // Get device position data
  const devicePositionName = item.positionInStore?.name;
  const devicePositionDepartment = item.positionInStore?.department;
  // If device is "unassigned"--no position name--it's compliant
  if (!devicePositionName) return false;
  const deviceMerchandiseCode = item.merchandise?.code;
  const deviceMerchandiseUPC = item.merchandise?.deviceUPC;
  // Find matching position in plan
  const sitePlanPosition = positionsInSitePlan.filter((position) =>
    position.name === devicePositionName && position.department === devicePositionDepartment);
  // If no matching position, it's not compliant
  if (!sitePlanPosition.length) return true;
  // If plan has no merchandise, it's compliant
  if (!sitePlanPosition[0].merchandiseName) return false;
  // If matching position exists, compare merchandise with device merchandise
  // If plan merchandise matches device merchandise, it's compliant
  return (sitePlanPosition[0].assignedUpcSku !== deviceMerchandiseCode
    && sitePlanPosition[0].assignedUpcSku !== deviceMerchandiseUPC);
};

export const isLockable = (item: SecuritySensor): boolean | undefined => {
  if (item.classId === 'onepod' && item.lastLockdownEvent && item.lastLockdownEvent?.ackRecd === true) {
    if ((item.lastLockdownEvent?.commandSent === 'lock' && item.currentState.isLockedDown)
      || (item.lastLockdownEvent?.commandSent === 'unlock' && !item.currentState.isLockedDown)) {
      return true;
    }
  }
  return undefined;
};

export const isAlarmNodeOnline = (item: SecuritySensor): boolean => item.isAlarmNodeOnline === true;

export const isAlarming = (item: SecuritySensor): boolean => item.currentState?.isAlarming;

export const isArmed = (item: SecuritySensor): boolean | undefined => item.currentState?.isArmed;

export const isDisarmed = (item: SecuritySensor): boolean | undefined =>
  !isInstallPending(item)
  && isSecuritySensorOnline(item)
  && item.currentState && item.currentState?.isArmed === false;

export const isIssue = (item: SecuritySensor, positionsInSitePlan: PlanogramPositionObject[]): boolean => {
  const { currentState } = item;
  if (!isSecuritySensorOnline(item)
    || isInstallPending(item) || isReArmingFailed(item) || !isArmed(item)
    || isComplianceIssue(item, positionsInSitePlan)
    || (currentState
      && (currentState?.isOnWrongStand
        || !currentState?.isPlanogramOk
        || (!currentState?.isArmedAsExpected
          && (currentState?.expectedArmedLoops?.recoiler !== currentState?.armedLoop?.recoiler
            || (currentState?.expectedArmedLoops?.apSense !== currentState?.armedLoop?.apSense)
            || (currentState?.expectedArmedLoops?.pigtail !== currentState?.armedLoop?.pigtail))
        )))) {
    return true;
  }
  return false;
};

export const isUnsecureStatus = (item: SecuritySensor): boolean => {
  if (isAlarming(item) || !isSecuritySensorOnline(item)
    || !isAlarmNodeOnline(item) || !isArmed(item)) {
    return true;
  }
  return false;
};

export const isOnePod = (item: SecuritySensor): boolean => item.classId === 'onepod';

export const isVertical = (item: SecuritySensor): boolean => item.classId === 'vertical';

export const isLocked = (item: SecuritySensor): boolean | undefined => {
  if (item.currentState && item.classId === 'onepod') {
    return item.currentState.isLockedDown;
  }
  return undefined;
};

export const getDescriptionBySensorData = (item: SecuritySensor): DescriptionText => {
  const { brand = '', name: merchandiseName = '' } = item.merchandise || {};
  const { department = '', name: positionName = '' } = item.positionInStore || {};
  let text = '';
  let subText = '';

  if (brand && merchandiseName) {
    text = `${brand}, ${merchandiseName}`;
  } else if (brand && !merchandiseName) {
    text = brand;
  } else if (!brand && merchandiseName) {
    text = merchandiseName;
  }

  if (department && positionName) {
    subText = `${department} | ${positionName}`;
  } else if (department && !positionName) {
    subText = department;
  } else if (!department && positionName) {
    subText = positionName;
  }

  return {
    text, subText, bold: true,
  };
};

export const isPlanogramFlagSet = (item: SecuritySensor): boolean => (item.currentState
  && item.currentState.isPlanogramOk) || false;

export const getIssue = (
  heartbeat: number,
  sensorData: SecuritySensor,
  labels: LabelsModel,
  positionsInSitePlan: PlanogramPositionObject[],
): string => {
  const issue: string[] = [];

  if (isInstallPending(sensorData)) {
    issue.push(labels.installPending);
  } else if (isIssue(sensorData, positionsInSitePlan)) {
    if (isReArmingFailed(sensorData)) {
      issue.push(labels.failedToRearm);
    } else if (!isAlarmNodeOnline(sensorData)) {
      issue.push(labels.attachedAlarmModuleOffline);
    } else if (!isSecuritySensorOnline(sensorData)) {
      issue.push(labels.offline);
    } else if (isComplianceIssue(sensorData, positionsInSitePlan)) {
      issue.push(labels.planogramCompliance);
    }
  }

  if (!sensorData.currentState?.isArmedAsExpected) {
    const { expectedArmedLoops, armedLoop } = sensorData?.currentState || {};
    // Comparing expected armed loop with actual armed loop, to find issue with sensor.
    if (expectedArmedLoops?.apSense !== armedLoop?.apSense) {
      issue.push(`\n${labels.merchandisePowerConnector}`);
    }
    if (expectedArmedLoops?.pigtail !== armedLoop?.pigtail) {
      issue.push(`\n${labels.sensorCableToMerchandise}`);
    }
    if (expectedArmedLoops?.auxPort !== undefined
      && expectedArmedLoops?.auxPort !== armedLoop?.auxPort) {
      issue.push(`\n${labels.auxiliaryPort}`);
    }
    if (expectedArmedLoops?.recoiler !== armedLoop?.recoiler) {
      issue.push(`\n${labels.sensorTetherCable}`);
    }
    if (expectedArmedLoops?.standPlunger !== armedLoop?.standPlunger) {
      issue.push(`\n${labels.standPlunger}`);
    }
    if (expectedArmedLoops?.sensorPlunger !== armedLoop?.sensorPlunger) {
      issue.push(`\n${labels.sensorPlunger}`);
    }
  }
  if (sensorData.currentState && sensorData.currentState.alarmingLoop) {
    const { apSense, pigtail, auxPort, recoiler,
      standPlunger, sensorPlunger } = sensorData.currentState?.alarmingLoop || {};
    if (apSense) {
      issue.push(`\n${labels.merchandisePowerConnector}`);
    }
    if (pigtail) {
      issue.push(`\n${labels.sensorCableToMerchandise}`);
    }
    if (auxPort) {
      issue.push(`\n${labels.auxiliaryPort}`);
    }
    if (recoiler) {
      issue.push(`\n${labels.sensorTetherCable}`);
    }
    if (standPlunger) {
      issue.push(`\n${labels.standPlunger}`);
    }
    if (sensorPlunger) {
      issue.push(`\n${labels.sensorPlunger}`);
    }
  }
  if (sensorData.currentState && sensorData.currentState?.isOnWrongStand) {
    issue.push(`\n${labels.sensorWrongStand}`);
  }
  if (!isPlanogramFlagSet(sensorData)) {
    issue.push(`\n${labels.confirmationNeeded}`);
  }

  if (issue.length) {
    const uniqueIssue: string[] = _.uniq(issue);
    if (uniqueIssue[0].charAt(0) === '\n') {
      uniqueIssue[0] = uniqueIssue[0].slice(1);
    }
    return firstLetterOfWordInUpperCase(uniqueIssue.join(''));
    // eslint-disable-next-line
  } else {
    issue.push(labels.none);
    return firstLetterOfWordInUpperCase(issue.join(''));
  }
};
